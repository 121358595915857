import React from "react"
import ConfiguredMUIDatable from "../configuredMuiDatable"
import { renderSingleUserCell } from "../singleUserCell"
import {
  CompletedStatusMessage,
  ArtistType,
  TechnologistType,
} from "../../../../constants"
import NewWindowLink from "../../../functionality/newWindowLink"
import { MarkProposalReviewed } from "../../../../client/client"
import { getUserTableCellData } from "../utils"
import AppButton from "../../../functionality/appButton"
import {
  TableRow,
  TableCell,
  Box,
  Container,
  Typography,
} from "@material-ui/core"

// If reviewed is true, then render a "Reviewed Proposals" table, otherwise render a "Proposals Awaiting Review" table
export default function CompletedProposalsTable({
  usersData,
  reviewed = false,
  onErrorFunc,
  onSuccessFunc,
  refreshFunc,
}) {
  const columns = [
    {
      label: "Id",
      name: "id",
    },
    {
      label: "Artist",
      name: "artist",
      options: {
        customBodyRender: renderSingleUserCell,
      },
    },
    {
      label: "Technologist",
      name: "technologist",
      options: {
        customBodyRender: renderSingleUserCell,
      },
    },
    {
      label: "Submitted",
      name: "submitted",
      options: {
        sort: true,
        sortDirection: "asc",
      },
    },
    {
      label: "SubmissionDetails",
      name: "submissionDetails",
      options: {
        display: "excluded",
      },
    },
  ]
  if (!reviewed) {
    columns.push({
      label: "Mark Done",
      name: "markDone",
      options: {
        customBodyRender: createRenderMarkDoneCell(
          onErrorFunc,
          onSuccessFunc,
          refreshFunc
        ),
      },
    })
  }
  let options = {
    search: false,
    expandableRows: true,
    renderExpandableRow: createExpandableRow,
    sort: true,
  }

  return (
    <ConfiguredMUIDatable
      title={reviewed ? "Reviewed" : "Awaiting Review"}
      data={createTableData(usersData, reviewed)}
      columns={columns}
      options={options}
    />
  )
}

// *******************************************************
function createTableData(usersData, reviewed) {
  // need to define it here because it must apply across users
  let selectorUUIDCache = {}
  return usersData.users.reduce(extractProposals, []).map(proposalToRowData)

  // *********************************************************
  function extractProposals(arrayOfProposals, user) {
    user.proposals.forEach(function extractProposalIfCompleted(curProposal) {
      if (
        !curProposal.activeOrUpcoming && // if its not activeOrUpcoming, its completed
        curProposal.statusMessage === CompletedStatusMessage &&
        // eslint-disable-next-line
        curProposal.reviewed == reviewed && // get reviewed proposals, or unreviewed proposals, as per the parent component designation
        selectorUUIDCache[curProposal.selectorUUID] !== true
      ) {
        arrayOfProposals.push(curProposal)
        selectorUUIDCache[curProposal.selectorUUID] = true
      }
    })
    return arrayOfProposals
  }

  function proposalToRowData(proposal) {
    let selector = usersData.usersIndexedByUUID[proposal.selectorUUID]
    let selectee = usersData.usersIndexedByUUID[proposal.selecteeUUID]

    // eslint-disable-next-line
    let artist = selector.craft == ArtistType ? selector : selectee
    // eslint-disable-next-line
    let technologist = selector.craft == TechnologistType ? selector : selectee

    let artistData = getUserTableCellData(artist)
    let technologistData = getUserTableCellData(technologist)

    return {
      id: proposal.proposalID,
      artist: artistData,
      technologist: technologistData,
      submitted: proposal.submittedAt,
      markDone: {
        proposalID: proposal.proposalID,
      },
      submissionDetails: {
        files: proposal.submissionFileLinks,
        links: proposal.submissionLinks,
        message: proposal.submissionMessage,
      },
    }
  }
}

// Use a closure to pass onErrorFunc into the namspace of renderChatCell
function createRenderMarkDoneCell(onErrorFunc, onSuccessFunc, refreshFunc) {
  return function renderMarkDoneCell(value, tableMeta, updateValue) {
    return <AppButton text={"Mark as Done"} onClick={markDone} />

    // ********************
    function markDone() {
      MarkProposalReviewed(value.proposalID)
        .then(function refreshPageAndAlertUser() {
          refreshFunc()
          onSuccessFunc("Marked Proposal Reviewed!")
        })
        .catch(function alertUserOfError() {
          onErrorFunc("Server Error. Please contact technical support")
        })
    }
  }
}

function createExpandableRow(rowData, rowMeta) {
  const colSpan = rowData.length + 1
  let submission = rowData[4]
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Container>
          <Box
            display="flex"
            maxWidth="70%"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            margin="0 auto"
            textAlign="center"
            py={2}
          >
            <Typography variant="h5" gutterBottom paragraph>
              Proposal Details
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>Files</Typography>
            {submission.files && submission.files.map(renderLink)}
            <br />
            <br />
            <Typography style={{ fontWeight: "bold" }}>Links</Typography>
            {submission.links && submission.links.map(renderLink)}
            <br />
            <br />
            <Typography style={{ fontWeight: "bold" }}>Message</Typography>
            {submission.message}
          </Box>
        </Container>
      </TableCell>
    </TableRow>
  )

  // **************
  function renderLink(link, index) {
    return !!link ? (
      <NewWindowLink url={link} text={`View item ${index}`} />
    ) : null
  }
}
