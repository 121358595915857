import React from 'react'
import CompletedProposalsTable from './completedProposalTable'

export default function AwaitingReviewTable ({
  usersData,
  onErrorFunc,
  onSuccessFunc,
  refreshFunc
}) {
  return (
    <CompletedProposalsTable
      usersData={usersData}
      onErrorFunc={onErrorFunc}
      onSuccessFunc={onSuccessFunc}
      refreshFunc={refreshFunc}
    />
  )
}
