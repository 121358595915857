import React, { useState } from 'react'
import Layout from '../../components/layout'
import { UseUsers } from '../../client/client'
import AwaitingReviewTable from '../../components/page/admin/completed/awaitingReview'
import ReviewedTable from '../../components/page/admin/completed/reviewed'
import {
  SuccessSnackBar,
  ErrorSnackBar
} from '../../components/functionality/snackBars'
import { Container, Box } from '@material-ui/core'

export default function MatchesPage () {
  const [refreshCount, setRefreshCount] = useState(0)
  var usersData = UseUsers(refreshCount)

  // State variables that assist in managing success/error snack bars
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarErrorOpen, setSnackBarErrorOpen] = useState(false)
  const [snackBarSuccessOpen, setSnackBarSuccessOpen] = useState(false)

  return (
    <Layout>
      <Container maxWidth='lg'>
        <Box py={2} />
        <AwaitingReviewTable
          usersData={usersData}
          onErrorFunc={createSnackBarDisplayFunc(setSnackBarErrorOpen)}
          onSuccessFunc={createSnackBarDisplayFunc(setSnackBarSuccessOpen)}
          refreshFunc={refreshView}
        />
        <ReviewedTable usersData={usersData} />
      </Container>
      <SuccessSnackBar
        message={snackBarMessage}
        open={snackBarSuccessOpen}
        onClose={closeSuccessSnackBar}
      />
      <ErrorSnackBar
        message={snackBarMessage}
        open={snackBarErrorOpen}
        onClose={closeErrorSnackBar}
      />
    </Layout>
  )

  // ******************************************
  function createSnackBarDisplayFunc (setSnackBarStateFunc) {
    return function displaySnackBar (message) {
      setSnackBarStateFunc(true)
      setSnackBarMessage(message)
    }
  }

  function closeSuccessSnackBar () {
    setSnackBarSuccessOpen(false)
  }

  function closeErrorSnackBar () {
    setSnackBarErrorOpen(false)
  }

  function refreshView () {
    setRefreshCount(refreshCount + 1)
  }
}
